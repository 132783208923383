<template>
  <div :class="[device==='desktop' && 'landing-inner-banner']">
    <h1 class="text-center mt-3 mb-0 mx-1" style="font-weight: bold;">{{$t("hotel-page.hotel-breadcrumb-dest-third-clone",
      {destinationName: destinationName})}}</h1>
    <div class="container">
      <div class="row" v-if="device==='desktop'">
        <LandingBannerSlide :dealTypeCode="dealTypeCode"/>
        <LandingContact :dealTypeCode="dealTypeCode"/>
      </div>
      <TopBanner :topBanner="topBanner" v-if="showTopBanner && !hideCondition && device==='mobile'"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'LandingBanner',
  mixins: [getBannerImage],
  components: {
    TopBanner: () => import('@/components/home/topBanner/TopBannerTheme2'),
    LandingBannerSlide: () => import('@/components/landing/LandingBannerSlide'),
    LandingContact: () => import('@/components/landing/LandingContact'),
  },
  props: {
    dealTypeCode: String,
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      hideCondition: 'GET_HIDE_CONDITION',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      return this.query.destination;
    },
    hotelCode() {
      return this.query.hotelCode;
    },
    destinationName() {
      if (this.destinationList.length === 0) return '';
      return this.destinationList.find((elem) => elem.code === this.cityId)?.name || '';
    },
  },
};
</script>

<style scoped>
  .landing-inner-banner {
    width: 100%;
    background: url('/assets/img/banner_landing.jpg');
    background-size: 100% 100%;
    padding-top: 45px;
    margin-top: -35px;
  }
  .container{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
  }
  @media (min-width: 1200px){
    .container {
        max-width: 1140px;
    }
  }
</style>
